(function () {
    var config = {
        storageNames: {
            customerStats: "customer-stats",
        },
    };

    flx.acct = {
        setCustomerStats: function (user) {
            if (!user) return;
            var stats = {
                approved: (user.status || "").toLowerCase() === "approved",
                spendingOnHold: user.spendingHold,
                spendingLimit: user.spendingLimit,
                weeklyLimit: user.weeklyLimit,
            };
            window.localStorage.setItem(
                config.storageNames.customerStats,
                JSON.stringify(stats)
            );
        },
        getCustomerStats: function () {
            var customerStats =
                window.localStorage[config.storageNames.customerStats];
            return customerStats ? JSON.parse(customerStats) : {};
        },
        removeCustomerStats: function () {
            window.localStorage.removeItem(config.storageNames.customerStats);

            // These are additional properties that were previously managed within template files
            // TODO: Need to verify if they're actively used and can be consolidated into the object above
            window.localStorage.removeItem('isTest');
            window.localStorage.removeItem('email');
            window.localStorage.removeItem('acquisition');
            flx.util.deleteCookie('loggedin');

            if (flx.util.getDeviceType() === 'mobile') {
                flx.cart.clearCartItemsCountForMobile();
            }           
        },
        reloadPunchData: function (options) {
            options = flx.util.isNotEmpty(options) ? options : {};

            return $.get("/fpc/api/punch-data", options, function (fpcData) {
                if (flx.miniCart) {
                    flx.miniCart.getMiniCartItems();
                }
                fpcData.actions.forEach(function (action) {
                    switch (action.type) {
                        case "class":
                            $(action.meta.selector).addClass(action.meta.value);
                            break;
                        case "replace":
                            $(action.meta.selector).replaceWith(
                                action.meta.value
                            );
                            break;
                    }
                });
                if (flx.mmMobile) {
                    flx.mmMobile.bindMenuClose();
                }
            });
        },

        /**
         * Fires AJAX call to entity endpoint with cta
         * @param  {String} cta - call-to-action which tells FPay how to handle the req
         * @param  {Object} options - (optional) options object
         * @return {Promise} Promise that resolves upon successful storage of FPay entity
         */
        fpayAction: function fpayAction(cta, options) {
            if (options === undefined) {
                options = {};
            }

            var originUrl = [
                location.protocol,
                "//",
                location.host,
                location.pathname,
            ].join("");

            var fpayBody = {
                cta: cta,
                originUrl: originUrl,
                originHref: flx.util.isEmpty(options)
                    ? location.href
                    : options.originHrefOverride,
            };

            // If the user has a current, valid coupon code, pass it along to FPAY
            if (flx.util.isNotEmpty(options.verifiedCouponCode)) {
                fpayBody.verifiedCouponCode = options.verifiedCouponCode;
            }

            // To POST final order to FPay, orderId is needed
            if (cta === "AGREEMENT") {
                fpayBody.orderId = options.orderId;
            }

            return $.ajax({
                url: "/fpay/entity",
                method: "POST",
                data: JSON.stringify(fpayBody),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
            });
        },

        /**
         * Redirects based to FPay destinationUrl
         * @param  {String} cta - call-to-action which tells FPay how to handle the req
         * @param  {Object} options - (optional) options object
         */
        fpayRedirect: function fpayRedirect(cta, options) {
            return function fpayRedirectCurried(e) {
                e.preventDefault();
                if (typeof FlexSDK === "undefined") {
                    flx.mmMobile && flx.mmMobile.closeMenu(null);
                    return flx.msg.showMsg(
                        "error",
                        {
                            header: "Oops, the plugin cannot be loaded at this time.",
                            content: "Please refresh the page and try again.",
                            alignment: "msgLeft",
                        },
                        "global"
                    );
                }
                var onLogin = function (claim) {
                    var spinnerId = flx.spinner.show();
                    var loginErrorMsg =
                        "There was a problem logging into your account. Please contact customer service for " +
                        "assistance: 1-855-353-9289";
                    $.post("/fpay/pp3-login-claim", { claim: claim })
                        .then(function (user) {
                            if (!user || !user.didLogin) {
                                flx.spinner.hideById(spinnerId);
                                return;
                            }
                            //setting this cookie so in fastly vcl we can bypass cache for logged in users
                            flx.util.setCookie('loggedin', 'yes', 30);
                            window.localStorage.setItem("isTest", user.isTest);
                            //to set customer info in local storage for retargeting
                            flx.acct.setCustomerStats(user);
                            if (flx.util.getDeviceType() === "mobile") {
                                flx.cart.clearCartItemsCountForMobile();
                                flx.cart.getCartItemsCountForMobile();
                            }
                            window.dataLayer &&
                                window.dataLayer.push({
                                    isTest: user.isTest,
                                });
                            flx.util.setUserData(user);
                            flx.acct
                                .reloadPunchData()
                                .then(function () {
                                    if (
                                        !user.status ||
                                        user.status.toUpperCase() !== "APPROVED"
                                    ) {
                                        flx.spinner.hideById(spinnerId);
                                        return;
                                    }

                                    location.reload();
                                })
                                .fail(function (err) {
                                    flx.spinner.hideById(spinnerId);
                                });                            
                        })
                        .catch((err) => {
                            flx.spinner.hideById(spinnerId);
                            flx.msg.showMsg(
                                "error",
                                {
                                    content: loginErrorMsg,
                                    alignment: "msgCenter",
                                },
                                "global"
                            );
                        });
                };

                var onDecision = function (claim) {
                    // This entire chunk is handled differently from the claim above in onLogin... Should it be same?
                    $.post("/fpay/pp3-login-claim", { claim: claim })
                        .then(function (user) {
                            if (!user.didLogin) {
                                return;
                            }
                            //setting this cookie so in fastly vcl we can bypass cache for logged in users
                            flx.util.setCookie('loggedin', 'yes', 30);
                            window.localStorage.setItem("isTest", user.isTest);
                            //to set customer info in local storage for retargeting
                            flx.acct.setCustomerStats(user);
                            window.dataLayer &&
                                window.dataLayer.push({
                                    isTest: user.isTest,
                                });
                            flx.util.setUserData(user);
                            flx.acct.reloadPunchData();

                            // Delay window reload until customer closes PP3. Reload required for updated prices in UI.
                            if($(".payment-plugin-container").length){
                                $(".payment-plugin-container").on(
                                    "DOMNodeRemoved",
                                    function () {
                                        flx.spinner.show();
                                        location.reload();
                                    }
                                );
                            }else {
                                flx.spinner.show();
                                location.reload();
                            }
                        })
                        .catch(function (err) {
                            console.error("Failed onDecision ===>", err);
                        });
                };

                var collectDebitCardInfo = flx.util.getCollectDebitCardInfo();
                if(collectDebitCardInfo === undefined){
                    collectDebitCardInfo = false;
                }

                var waterfallEnabled = flx.util.getWaterfall();
                if(waterfallEnabled === undefined){
                    waterfallEnabled = false;
                }

                let disableACHCollection = flx.util.getDisableACHCollectionFlag();
                if (disableACHCollection === undefined) {
                    disableACHCollection = false;
                }

                var intent = {
                    disableACHCollection: JSON.parse(disableACHCollection),
                    collectDebitCardInfo: JSON.parse(collectDebitCardInfo),
                    mfa: { email: true, sms: true },
                    waterfall: JSON.parse(waterfallEnabled),
                    showCustomSpendingLimit: true,
                    displayConsentOnStartup: true
                };

                return FlexSDK.Modal({
                    onLogin,
                    onDecision,
                    options: {
                        clickId: window.sessionStorage.getItem("clickId"),
                        gclid: window.sessionStorage.getItem("gclid"),
                        isTest: window.localStorage.getItem("isTest"),
                        url: window.sessionStorage.getItem("url"),
                    },
                    intent,
                }).render('#payment-plugin-container');
            };
        },

        /**
         * Binds the click handlers for the login and apply header btns
         * @param  {Object} selectors - object with jQ selector strings as vals
         *
         * NOTES:
         *     - When DOM structure is updated, must change selectors accordingly
         */
        bindAcctBtns: function bindAcctBtns(selectors) {
            $("body").on(
                "click.fpayActionLogin",
                selectors.login,
                flx.acct.fpayRedirect("LOGIN")
            );
            $("body").on(
                "click.fpayActionApply",
                selectors.apply,
                flx.acct.fpayRedirect("SIGNUP")
            );
        },

        /**
         * Initializes the login / apply btns when user is not already logged in
         *     - Checks a hidden input for logged-in / logged-out status
         *     - Fires the bindAcctBtns method if user is a guest
         */
        initAcct: function initAcct() {
            // Attach spinner to account element in header while loading. This spinner is removed
            // by the HTML replacement being done in the punch-data file rather than spinner.hide
            var spinnerId = flx.spinner.show({
                spinnerGlobal: false,
                target: ".fpc-myAccount",
            });

            flx.acct.bindAcctBtns({
                login: ".fpayActionLogin",
                apply: ".fpayActionApply",
            });
        },
    };

    flx.acct.initAcct();
})();
