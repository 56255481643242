(function () {
    flx.addingToCart = false;
    flx.app = {
        onAddItemToCart: function (addToCartData) {
            if (flx.addingToCart) return;
            flx.addingToCart = true;
            const { product, quantity } = addToCartData;
            flx.cart.multiAddToCart(product.productId, product.inventoryId, quantity);
        },

        /**
         * Handler for the additional vendors displayed on PDP version of product page
         *
         * @param {Object} [targetVendorAndProductData]
         * @param {Object} [targetVendorAndProductData.vendorData] This data extracted within pp3 from primaryVendorProductData
         * @param {Object} [targetVendorAndProductData.qty] Currently static amount set within pp3 for later use if ever needed
         * @param {Object} [targetVendorAndProductData.primaryVendorProductData] This is the data used for rendering the overall page. It also contains the vendorData nested within.
         * @return {Undefined}
         */
        onClickOtherVendor: function (targetVendorAndProductData) {
            var targetVendorData = targetVendorAndProductData.vendorData;
            document.location.href = document.location.origin +
                document.location.pathname +
                "?selectedSku=" +
                targetVendorData.sku;
        },

        onRemoveFromCart: function(cartItemId) {
            flx.cart
                .removeFromCart(cartItemId)
                .done(function (res) {
                    if (flx.util.getDeviceType() === 'mobile') {
                        flx.cart.clearCartItemsCountForMobile();
                        flx.cart.getCartItemsCountForMobile();
                    }
                    flx.acct.reloadPunchData({ refreshShipping: true });
                });
        },

        onRemoveAllCartItems: function(cartId) {
            flx.cart
                .removeAllCartItems(cartId)
                .done(function () {
                    if (flx.util.getDeviceType() === 'mobile') {
                        flx.cart.clearCartItemsCountForMobile();
                        flx.cart.getCartItemsCountForMobile();
                    }
                    flx.acct.reloadPunchData({ refreshShipping: true });
                });
        },

        onAddRemoveFaOfferToCart: function(value) {
            flx.cart
                .addRemoveFlexAdvantage(value)
                .done('FlexAdvantage successfully added');
        },

        startPP3: function(orderInfo, intent) {
            var collectDebitCardInfo = flx.util.getCollectDebitCardInfo();
            if(collectDebitCardInfo === undefined){
                collectDebitCardInfo = false;
            }

            var waterfallEnabled = flx.util.getWaterfall();
            if(waterfallEnabled === undefined){
                waterfallEnabled = false;
            }

            let disableACHCollection = flx.util.getDisableACHCollectionFlag();
            if (disableACHCollection === undefined) {
                disableACHCollection = false;
            }
            var fulfillmentProvider = flx.util.getFulfillmentProvider();
            if(orderInfo && orderInfo.orderData && orderInfo.orderData.items){                                
                orderInfo.orderData.items.forEach(i=>{
                    if(fulfillmentProvider === 'logicbroker'){                        
                        i.vendorId = i.vendorCode;
                    }
                    i.provenance = fulfillmentProvider;
                    delete i.vendorCode;
                });
            }

            intent = {
                ...intent,
                mfa: { email: true, sms: true },
                disableACHCollection: JSON.parse(disableACHCollection),
                collectDebitCardInfo: JSON.parse(collectDebitCardInfo),
                waterfall: JSON.parse(waterfallEnabled),
                displayConsentOnStartup: true,
            };
            FlexSDK.Modal({
                ...orderInfo,
                options: {
                    clickId: window.sessionStorage.getItem("clickId"),
                    gclid: window.sessionStorage.getItem("gclid"),
                    isTest: window.localStorage.getItem("isTest"),
                },
                intent,
                onLogin: flx.paymentPlugin.onLogin,
                onDecision: flx.paymentPlugin.onDecision,
                onExit: flx.paymentPlugin.onExit,
                onDeclineOffer: flx.paymentPlugin.onDeclineOffer,
                onSign: flx.paymentPlugin.onSign,
                onExit: flx.paymentPlugin.onExit,
            }).render("#payment-plugin-container");
        },

        onChangeCartItemQuantity: function(cartItemId, qty) {
            flx.cart.updateItemQty(cartItemId, qty)
                .done(function (res) {
                    if (flx.util.getDeviceType() === 'mobile') {
                        flx.cart.clearCartItemsCountForMobile();
                        flx.cart.getCartItemsCountForMobile();
                    }
                    flx.acct.reloadPunchData({ refreshShipping: true });
                });
        },

        onSubmitCoupon: function(couponCode, responder) {
            if (!couponCode || !couponCode.length) return;
            var spinnerId = flx.spinner.show();
            flx.coupon.getCouponCall(couponCode)
                .done((response) => {
                    if (!response.valid) return responder.fail(new Error('Invalid Coupon'));
                    responder.success({ code: couponCode, label: response.name })
                })
                .fail(responder.fail)
                .always(function(){
                    flx.spinner.hideById(spinnerId);
                });
        },

        onUpdateZipCode: function(zipCode) {
            flx.util.setCookie('defaultZipCode', zipCode, 365);
            location.reload();
        }
    };

})();
