(function () {

    // jQ Selectors
    var $globalWrap;
    var $globalMsgBody;
    var $mcWrap;

    // Important data used by the messaging service
    var msgTypes;
    var alignmentTypes;
    var locationTypes;
    var locationMap;

    flx.msg = {

        /**
         * Cache elements and info objects for use after DOM has been updated
         */
        cacheMsgVars: function cacheMsgVars () {
            $globalWrap = $('.globalNotifications');
            $globalMsgBody = $globalWrap.find('.notificationWrap');
            $mcWrap = $('.miniCartNotification');
            $pageLoadMsg = $('.js-frontEndMessaging');
            msgTypes = ['error', 'success', 'info', 'alert'];
            alignmentTypes = ['msgLeft', 'msgCenter', 'msgRight'];
            locationTypes = ['global', 'miniCart'];
            locationMap = {
                global: {
                    wrapTarget: '.globalNotifications',
                    $wrap: $globalWrap,
                    $body: $globalMsgBody
                },
                miniCart: {
                    wrapTarget: '.miniCartNotification',
                    $wrap: $mcWrap,
                    $body: $mcWrap
                },
            };
        },

        /**
         * Shows message in notification banner
         * @param  {String} type - msg type (permitted values are in 'msgTypes' array)
         * @param  {{alignment: string, content: string}} options - msg options (alignment, header, and content)
         * - NOTE: It can be a string or an object
         * @param  {String} location - which banner to add msg to
         * - NOTE: Msg defaults to 'There was an error' if empty
         * - NOTE: will not execute if type or location are not found in the arrays:
         * (locationTypes and msgTypes)
         */
        showMsg: function showMsg (type, options, location) {

            // Need to recache elements and info objects in case DOM has updated
            flx.msg.cacheMsgVars();

            // Re-init vars
            var $body = locationMap[location].$body;
            var htmlContent = '';

            // If called with unsupported msg type, error out
            if (msgTypes.indexOf(type) === -1) {
                return console.log('Must provide one of the following msg types: '
                    + msgTypes.join(', '));
            }

            // If called with unsupported location type, error out
            if (locationTypes.indexOf(location) === -1) {
                return console.log('Must provide one of the following locations: '
                    + locationTypes.join(', '));
            }

            // If called with unsupported options type (must be string or an object), error out
            if (typeof options !== 'object' && typeof options !== 'string') {
                return console.log('Must provide one of the following msg types: '
                    + 'object or string');
            }

            if (flx.util.isEmpty(options)) {
                htmlContent = 'There was an error';
            }

            // Use options object (otherwise it's just a simple string)
            if (typeof options === 'object' && flx.util.isEmpty(htmlContent)) {

                var msgHeader = options.header || '';
                var msgContent = options.content || '';
                var msgAlignment = options.alignment || '';

                if (flx.util.isNotEmpty(msgHeader)) {
                    htmlContent += '<h1 class="globalMsgHeader">' + msgHeader + '</h1>';
                }

                if (flx.util.isNotEmpty(msgContent)) {
                    htmlContent += '<p class="globalMsgContent">' + msgContent + '</p>';
                }

            } else {

                htmlContent = htmlContent || options;

            }

            $body.removeClass(alignmentTypes.join(' ')); // Avoid stacking modifier classes
            $body.removeClass(msgTypes.join(' ')); // Avoid stacking modifier classes
            $body.addClass(msgAlignment);
            $body.addClass(type).html(htmlContent);
        },

        /**
         * Hides global notification messages
         * @param {array} matchText - array of text contained within the notification message
         *
         * NOTES:
         *     - Loops through locationMap, identifies targets, clears text & removes msgType class
         *     - Will not hide msg if it contains text OTHER than what's in the matchText messages
         *     (This is so we don't unintentionally hide unrelated warning msgs)
         */
        hideMsg: function hideMsg (matchText) {

            var $msgBody;

            // If no matchText was provided, just hide all the msgs
            if (flx.util.isEmpty(matchText)) {

                for (var location in locationMap) {
                    $msgBody = locationMap[location].$body;
                    for (var i = 0; i < msgTypes.length; i++) {
                        $msgBody.html('').removeClass(msgTypes[i]);
                    }
                }

            } else { // Otherwise, hide notification ONLY if matchText is found

                for (var location in locationMap) {

                    $msgBody = locationMap[location].$body;
                    var msgBodyText = $msgBody.text();

                    // Iterate over values in match text and check if it is there
                    for (var matchIndex = 0; matchIndex < matchText.length; matchIndex++) {

                        var currMatchText = matchText[matchIndex];

                        // If there was a match remove style class and msg text from div
                        if (msgBodyText.indexOf(currMatchText) !== -1) {

                            // Iterate over msgTypes and remove classes, if there
                            for (var i = 0; i < msgTypes.length; i++) {
                                $msgBody.html('').removeClass(msgTypes[i]);
                            }

                        }

                    }

                }

            }

        },

        /**
         * Binds click handlers to close notification messages
         * - Binds to document for 'closeMsg' event
         * - Fires 'closeMsg' event when any location.$wrap is clicked
         */
        bindMsgClose: function bindMsgClose () {
            $(document).on('closeMsg', flx.msg.hideMsg);
            for (var location in locationMap) {
                $wrap = locationMap[location].$wrap;
                $wrap.on('click', function msgCloseTrigger () {
                    $(document).trigger('closeMsg');
                });
            }
        },

        /**
         * Checks for a message cookie, parses the cookie, and then calls showMsg
         * The cookie needs to contain the message and message type
         * The message location can be 'miniCart', 'global', or 'both'
         * If no locaation is set it will be set to both
         */
        showPageLoadMsg: function showPageLoadMsg (interval) {

            var cookieMsgParsed = flx.util.safeParse(flx.util.getCookie('msgCookie')) || {};

            if (flx.util.isNotEmpty(cookieMsgParsed.type)
                && flx.util.isNotEmpty(cookieMsgParsed.msg)) {

                // Set message info
                var msgType = cookieMsgParsed.type;
                var msgLocation = cookieMsgParsed.location || 'both';
                var msgAlignment = cookieMsgParsed.alignment || 'msgCenter';
                var msgContent = {content: cookieMsgParsed.msg, alignment: msgAlignment};

                if (msgLocation !== 'both') {

                    flx.msg.showMsg(msgType, msgContent, msgLocation);

                } else {

                    flx.msg.showMsg(msgType, msgContent, 'global');
                    flx.msg.showMsg(msgType, msgContent, 'miniCart');

                }

                // Bind hide msg to document click (removes handler after single click)
                // NOTE: we give a short timeout here to avoid accidental double-clicks
                setTimeout(function () {
                    $(document).one('click.showPageLoadMsg', function (e) {
                        flx.msg.hideMsg();
                    });
                }, interval || 750);

                flx.util.deleteCookie('msgCookie');
            }

        }

    };

    flx.msg.bindMsgClose();
    flx.msg.cacheMsgVars();
    flx.msg.showPageLoadMsg();

})();
